html, body {
  height: 100vh;
  width: 95vw;
  background-color: black;
  color: white;
}

@font-face{ 
  font-family: 'StoryBook';
	src: url('./assets/fonts/StoryBook/StoryBook.eot');
  src: url('./assets/fonts/StoryBook/StoryBook.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/StoryBook/StoryBook.woff2') format('woff2'),
	     url('./assets/fonts/StoryBook/StoryBook.woff') format('woff'),
       url('./assets/fonts/StoryBook/StoryBook.ttf') format('truetype'),
       url('./assets/fonts/StoryBook/StoryBook.svg#StoryBook') format('svg');
}

.App {
  background-color: black;
  color: #E1E1E1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.header {
  opacity: 0;
}

#InitialSplash > * {
  opacity: 0;
}

.splash-info > * {
  opacity: 0;
}

.info-section {
  margin-bottom: 70px;
  opacity: 0;
}

* {
  transition: .2s all;
}

ul {
  list-style: none;
}


a {
  text-decoration: none;
}

p {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}

/* Animations */

.zero-to-hundred-opacity {
  animation: forwards zeroToHundredOpacity;
  animation-duration: 1s;
  -webkit-animation: 1s forwards zeroToHundredOpacity;
}

.zero-to-hundred-opacity-slow {
  animation: forwards zeroToHundredOpacity;
  animation-duration: 2s;
  -webkit-animation: 2s forwards zeroToHundredOpacity;
}


@keyframes zeroToHundredOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

@-webkit-keyframes zeroToHundredOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

/* Header */
.header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 40px;
  padding: 10px;
  z-index: 3;
}

.headerExpanded {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.header-expanded-icon {
  width: 20px;
  height: 20px;
  color: white;
}

.header-expanded-icon svg {
  width: 20px;
  height: 20px;
}

.header-expanded-icon:hover {
  color: #ff8a00;
  cursor: pointer;
}

.headedContracted {
  font-family: 'StoryBook', sans-serif;
  color: #ff8a00;
  font-size: 15px;
}

/* Splash */
#InitialSplash {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 60px;
}

#InitialSplash h1 {
  height: 90px; 
  font-family: 'StoryBook', sans-serif;
  font-size: 80px;
  font-weight: bold;
  color: #ff8a00;
  background-image: linear-gradient(to right, #ff8a00 0%, #da1b60 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  overflow: visible;
  font-weight: normal;
}

#InitialSplash h2 {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

#InitialSplash .headshot-container {
  height: 45vh;
  max-height: 45vh;
}

#InitialSplash .headshot {
  border-radius: 50%;
  max-height: 45vh;
  margin: 0 auto;
}

#InitialSplash .splash-info {
  height: 40vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.splash-info h3 {
  width: 100%;
  font-size: 15px;
  color: #ff8a00;
  background-image: linear-gradient(to right, #ff8a00 0%, #da1b60 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  overflow: visible;
  color: white;
}

.splash-info h3 span {
  z-index: 2;
  position: relative;
}

.contact-link-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.contact-link-list li {
  width: 100%;
  position: relative;
}

.contact-link-list li:hover * {
  opacity: 1;
}

.contact-link-list svg {
  width: 20px;
  height: 20px;
  color: white;
}

.contact-link-list .externalClickIcon {
  position: absolute;
  z-index: 3;
  top: 0px;
  width: 10px;
  height: 10px;
  opacity: 0;
  transform: rotate(45deg);
}


.externalGithubLink:hover .external-icon {
  color: #c965f7;
}

.externalLinkedInLink:hover .external-icon {
  color: #2987cf;
}

.externalEmailLink:hover .external-icon { 
  color: #ff6376;
}

.contact-link-list li:hover {
  cursor: pointer;
}

.info-section {
  width: 100%;
  margin-top: 0px;
}

.info-section:hover h4 {
  color: #ff6376;
}

.section-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
}

.section-header .headerIcon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

/* About Me */
.about-me {
  margin-top: 100px;
}

.about-me h5 {
  text-align: left;
}

/* Experience */

.experiences h4 {
  margin-bottom: 30px;
}

.experiences .experience-item {
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 35px;
}

.experiences .experience-item .bullet-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  display: block;
  align-self: flex-start;
  margin-top: 15px;
  z-index: 2;
}

.experiences .experience-item .bullet-bar {
  position: absolute;
  width: 4px;
  background-color: #999;
  opacity: 0.4;
  height: 100%;
  top: 0;
  left: 3px;
}

.experiences .experience-item .experience-item-info {
  flex: 1;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
  text-align: left;
}

.experiences .experience-item .experience-item-info .experience-logo {
  max-height: 60px;
  margin-bottom: 10px;
}

.experiences .experience-item .experience-item-info .experience-name {
  display: flex;
  margin: 10px 0px 0px 0px;
  align-items: center;
}

.experiences .experience-item .experience-item-info h5 {
  font-size: 16px;
  margin: 0;
  margin-right: 10px;
}

.experiences .experience-item .experience-item-info .experience-dates {
  font-size: 11px;
}

.experiences .experience-item .experience-item-info .experience-role {
  font-size: 14px;
  margin-bottom: 10px;
}

.experiences .experience-item .experience-item-info .experience-tasks {
  list-style: inside;
  font-size: 13px;
  margin-left: 15px;
}

.experiences .experience-item .experience-item-info .experience-task {
  padding-bottom: 5px;
}

.experiences .show-more-experiences {
  background: none;
  border: none;
  color: #999;
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  width: 100%;
  margin-top: 15px;
}

.experiences .show-more-experiences:hover {
  cursor: pointer;
}

/* Skills */
.skills .skill {
  width: 100%;
  display: flex;
  border: 1px solid #222222;
  border-radius: 5%;
  padding: 10px;
  align-items: center;
  margin: 5px 0px;
}

.skills .skill:hover {
  border: 1px solid #555555;
}

.skills .skill .skill-icon-container {
  width: 50px;
  display: flex;
  align-items: center;
}

.skills .skill .skill-icon {
  width: 20px;
  height: 20px;
}

.skills .skill .skill-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 30px;
}

.skills .skill .skill-title {
  font-weight: bold;
  font-size: 13px;
}

.skills .skill .skill-description {
  font-size: 11px;
}


/* Projects */


.projects h4 {
  margin-bottom: 30px;
}

.projects p {
  font-size: 13px;
  line-height: 17px;
}

.project-list {
  position: relative;
}

.project-list-item {
  position: relative;
  margin-bottom: 90px;
}

.project-list-item:last-of-type {
  margin-bottom: 0px;
}
.project-list h5 {
  text-align: left;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.screenshot-container-behind {
  max-height: 200px;
  overflow: hidden;
  position: absolute;
  top: -20px;
  left: -10px;
}

.screenshot-container-original {
  max-height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 5%;
}

.screenshot-container-overlay {
  height: 100%;
  width: 100%;
  background-color: inherit;
  z-index: 4;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screenshot-container-original:hover .screenshot-container-overlay {
  cursor: pointer;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
}

.hover-link-icon {
  opacity: 0;
  width: 80px;
  height: 80px;
  z-index: 4;
  color: white;
  align-self: center;
}

.screenshot-container-original:hover .screenshot-container-overlay .hover-link-icon {
  opacity: 0.7;
}


.screenshot-container-behind img {
  margin-top: -20px;
  overflow: hidden;
  width: 95%;
}

.screenshot-container-original img {
  margin-top: -50px;
  overflow: hidden;
}

.screenshotBehind {
  opacity: 0.3;
  filter: grayscale(100%);
}

.screenshotOriginal {
  z-index: 1;
}

.project-list {
  margin-top: 15px;
  margin-bottom: 100px;
}

.project-links-list {
  display: flex;
}

.project-link-item .external-link {
  width: 20px;
  height: 20px;
  color: white;
  margin-right: 20px;
  margin-bottom: 10px;
}

.project-link-item .external-link:hover {
  color: #ff8a00;
}

.project-tech-list {
  display: flex;
  flex-wrap: wrap;
}

.tech-arrow {
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
  margin-right: 15px;
}

.project-tech-list .project-tech-item {
  font-size: 11px;
  font-weight: bold;
  margin-right: 7px;
  text-transform: uppercase;
  color: white;
}

/* Resume */

.resume h4 {
  margin-bottom: 15px;
}

.resume-button {
  display: block;
  background-color: white;
  border: 1px solid #666;
  padding: 10px;
}

.resume-button:hover {
  background-color: white;
  color: #ff8a00;
}


/* Responsive */

@media only screen and (min-width: 768px), only screen and (max-height: 550px) {
  #InitialSplash {
    width: 80vw;
    flex-direction: row;
    align-items: center;
  }

  #InitialSplash .headshot-container {
    width: 60vw;
  }

  #InitialSplash .splash-info {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .info-section {
    padding-left: 40px;
    padding-right: 40px;
  }

  .skills h5 {
    text-align: left;
  }

  .skills-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .skill {
    max-width: 50%;
  }

  .desktop-flex {
    width: 100%;
  }
}

@media only screen and (min-width: 1028px) {
  #InitialSplash h1 {
    font-size: 110px;
    height: 130px;
  }

  #InitialSplash .headshot-container {
    height: 70vh;
    max-height: 70vh;
  }
  
  #InitialSplash .headshot {
    border-radius: 50%;
    max-height: 70vh;
    margin: 0 auto;
  }

  #InitialSplash .splash-info {
    height: 55vh;
  }

  .project-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .project-list-item {
    width: 45%;
    margin-right: 40px;
  }

  .skills .skill {
    width: 45%;
    display: flex;
    border: 1px solid #222222;
    border-radius: 5%;
    padding: 10px;
    align-items: center;
    margin: 5px 10px 5px 0px;
  }

  .desktop-flex {
    display: flex;
  }

  .skills, .experiences {
    width: 45%;
    flex: 1;
  }

  .contact-link-list svg {
    width: 40px;
    height: 40px;
  }

  .contact-link-list .externalClickIcon {
    width: 20px;
    height: 20px;
  }
  
}

@media only screen and (min-width: 1060px) {
  #InitialSplash h1 {
    font-size: 120px;
    height: 140px;
  }
}

@media only screen and (max-width: 702px) and (max-height: 550px) {
  #InitialSplash h1 {
    height: 85px;
    font-size: 75px;
  }
}